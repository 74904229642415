:root {
  --btd-background-color: #171b2f;
  --btd-text-color: #ffffff;
  --btd-text-accent-color: #7799cd;
  --btd-accent-button-color: #6b8cbe;
  --btd-secondary-button-color: transparent;

  --btd-unit-1: 0.25rem;
  --btd-unit-2: 0.5rem;
  --btd-unit-3: 0.75rem;
  --btd-unit-4: 1rem;
  --btd-unit-5: 1.25rem;
  --btd-unit-6: 1.5rem;
  --btd-unit-7: 1.75rem;
  --btd-unit-8: 2rem;
  --btd-unit-9: 2.25rem;
  --btd-unit-10: 2.5rem;
  --btd-unit-11: 2.75rem;
  --btd-unit-12: 3rem;
  --btd-unit-13: 3.25rem;
  --btd-unit-14: 3.5rem;
  --btd-unit-15: 3.75rem;
  --btd-unit-16: 4rem;

  --btd-viewport-sm: 576px;
  --btd-viewport-md: 768px;
  --btd-viewport-lg: 992px;
  --btd-viewport-xl: 1200px;
  --btd-viewport-xxl: 1400px;
}

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: var(--btd-background-color) !important;
  color: var(--btd-text-color) !important;
  font-family: Montserrat !important;
}

#root {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: var(--btd-text-color);
}

a:hover {
  color: var(--btd-text-accent-color);
}
