.contact {
  background-color: #171b2f;
  color: var(--btd-color-text);
  border-radius: var(--btd-unit-2);
  position: absolute;
  top: calc(50% - 200px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 400px;
  z-index: 3;
}

.roles {
  display: flex;
  flex-direction: row;
  gap: var(--btd-unit-3);
}

.role-label {
  margin: 0 var(--btd-unit-1);
}

.close {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.close-btn {
  background-color: transparent;
  color: var(--btd-color-text);
  border: none;
  cursor: pointer;
}

.body {
  min-height: 300px;
}
