.container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: auto;
  justify-content: center;
  min-height: 90vh;
  padding: var(--btd-unit-8);
  z-index: 2;
  max-width: var(--btd-viewport-lg);
}

.header {
  font-size: var(--btd-unit-8);
  font-weight: 700;
  margin-bottom: 1rem;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 var(--btd-unit-8);
  z-index: 2;
}

.links {
  font-size: 16px;
  display: flex;
  gap: var(--btd-unit-8);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.resume-btn {
  background-color: var(--btd-accent-button-color);
  color: var(--btd-text-color);
  border: none;
  border-radius: 4px;
  padding: var(--btd-unit-3) var(--btd-unit-6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 57px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.resume-btn:hover {
  background-color: var(--btd-text-accent-color);
}

.lets-talk-btn a,
.resume-btn a {
  text-decoration: none;
}

.lets-talk-btn a:hover,
.resume-btn a:hover {
  color: var(--btd-text-color);
}

.top-gradient {
  filter: blur(125px);
  position: absolute;
  top: 0;
  left: 0;
  width: 452px;
  height: 452px;
  border-radius: 452px;
  z-index: 1;
  background: linear-gradient(180deg, #50369e 0%, #2c467f 55.68%, #276a6f 106.02%);
}

.bottom-gradient {
  filter: blur(125px);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 370px;
  height: 370px;
  border-radius: 370px;
  z-index: 1;
  background: linear-gradient(180deg, #982b9a 0%, #3f398f 49.37%, #346079 100%);
}

.intro {
  font-size: var(--btd-unit-5);
}

.title {
  font-family: "DM Mono", monospace;
  font-size: var(--btd-unit-14);
  margin: var(--btd-unit-4) 0;
  text-align: center;
}

.description {
  font-size: var(--btd-unit-5);
  margin-top: var(--btd-unit-4);
  margin-bottom: var(--btd-unit-4);
  line-height: 125%;
  padding: 0 var(--btd-unit-5);
  text-align: center;
}

.ctas {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--btd-unit-8);
  margin-top: var(--btd-unit-4);
}

.lets-talk-btn {
  background-color: transparent;
  color: var(--btd-text-color);
  border: 1px solid var(--btd-text-color);
  border-radius: 4px;
  padding: var(--btd-unit-3) var(--btd-unit-6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 57px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.lets-talk-btn:hover {
  opacity: 0.8;
}

.arrow {
  margin-left: var(--btd-unit-3);
  font-size: 16px;
}

.dots-bottom {
  position: absolute;
  bottom: var(--btd-unit-5);
  left: var(--btd-unit-5);
  z-index: 1;
}

.dots-top {
  position: absolute;
  top: calc(var(--btd-unit-1) * 4);
  right: calc(var(--btd-unit-14) * -1);
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.dots-top,
.dots-bottom {
  width: 143px;
  height: 74px;
}

.social-icons {
  position: absolute;
  bottom: 116px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--btd-unit-3);
  rotate: -90deg;
  font-size: var(--btd-unit-4);
}

.social-line {
  width: 50px;
  height: 4px;
  background-color: #fff;
}

.icon {
  rotate: 90deg;
}

.hamburger {
  display: none;
}

.accent {
  color: var(--btd-text-accent-color);
}

@media (max-width: 1200px) {
  .social-icons {
    rotate: none;
    bottom: 0;
    right: var(--btd-unit-8);
    gap: var(--btd-unit-4);
  }

  .icon {
    rotate: none;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 var(--btd-unit-6);
  }
}

@media (max-width: 576px) {
  .header {
    padding: 0 var(--btd-unit-4);
  }

  .links {
    display: none;
  }

  .hamburger {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;
  }

  .dots-top {
    top: calc(var(--btd-unit-1) * 30);
    right: calc(var(--btd-unit-5) * -1);
  }

  .dots-top,
  .dots-bottom {
    width: 70px;
    height: 36px;
  }

  .title {
    font-size: var(--btd-unit-7);
    margin-bottom: var(--btd-unit-2);
  }

  .description {
    font-size: var(--btd-unit-4);
    margin-top: var(--btd-unit-2);
    margin-bottom: var(--btd-unit-2);
    line-height: 180%;
  }
}
