.header {
  font-size: var(--btd-unit-8);
  font-weight: 700;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 var(--btd-unit-8);
  z-index: 2;
}

.links {
  font-size: 16px;
  display: flex;
  gap: var(--btd-unit-8);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.sidebar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  gap: var(--btd-unit-8);
  max-width: 250px;
}

.container {
  display: flex;
  flex-direction: row;
  height: 90vh;
}

.main {
  display: flex;
  min-width: 300px;
}
