.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: var(--btd-background-color);
  color: var(--btd-text-color);
  min-height: 100vh;
  min-width: 100vw;
}

.links {
  display: flex;
  position: relative;
  top: calc(var(--btd-unit-1) * 25);
  left: calc(var(--btd-unit-1) * 8);
  flex-direction: column;
  align-items: start;
  gap: var(--btd-unit-4);
}

.close {
  position: absolute;
  top: var(--btd-unit-4);
  right: var(--btd-unit-4);
  font-size: var(--btd-unit-8);
  color: var(--btd-text-color);
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.closed {
  position: fixed;
  z-index: 3;
  top: 0;
  right: -100%;
  animation: slideOut 0.5s forwards;
}

.open {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  animation: slideIn 0.5s forwards;
}

.contact-btn {
  background-color: var(--btd-accent-button-color);
  color: var(--btd-text-color);
  border: none;
  border-radius: 4px;
  padding: var(--btd-unit-3) var(--btd-unit-6);
  font-size: 16px;
  font-weight: 700;
  border-radius: 57px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.contact-btn:hover {
  background-color: var(--btd-text-accent-color);
}

@keyframes slideIn {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@keyframes slideOut {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
